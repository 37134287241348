<template>
  <main v-if="page" class="layout-default -home">
    <div class="header-home">
      <h1 class="title text-xl-vs text-7xl-vl">
        <EmphasizedText
          v-if="page.siteTitle"
          class="wrapper"
          :data="page.siteTitle"
        />
      </h1>
    </div>

    <ArticleList
      :article-cards="allArticles"
      :article-types="allArticleTypes"
      :topics="allTopics"
    />
  </main>
</template>

<script setup lang="ts">
import { useSeo } from '~/utils/useSeo';
import ArticleList from '~/components/article/ArticleList.vue';
import type { GetArticleCardsQuery, GetHomePageQuery } from '#gql';
import { useGlobalData } from '~/datocms/useGlobalData';
import { lastContentHubScrollTop } from '~/components/article/useArticleContentHub';
// import { useContainerIntersections } from '~/utils/useContainerIntersections';

const i18n = useI18n();
const { siteInfo } = useGlobalData();

const { data, error } = await useAsyncData<GetHomePageQuery>(
  `home-${i18n.localeProperties.value.siteLocale}`,
  () =>
    $fetch('/api/content/home', {
      query: {
        locale: i18n.localeProperties.value.siteLocale,
      },
      headers: {
        'Cache-Control': 'no-cache', // TODO: temporary solution to make caching system work
      },
    }),
);

const { data: articleData } = await useAsyncData<GetArticleCardsQuery>(
  `article-cards-${i18n.localeProperties.value.siteLocale}`,
  () =>
    $fetch('/api/content/article-cards', {
      query: {
        locale: i18n.localeProperties.value.siteLocale,
      },
      headers: {
        'Cache-Control': 'no-cache', // TODO: temporary solution to make caching system work
      },
    }),
);

const page = data.value?.homePage ?? null;
const allArticles = articleData.value?.allArticles ?? [];
const allArticleTypes = articleData.value?.allArticleTypes ?? [];
const allTopics = articleData.value?.allTopics ?? [];

if (!page || error.value) {
  showError({
    statusCode: 404,
  });
}

if (page) {
  useSeo(page._seoMetaTags ?? []);
  useHead({
    title: computed(() => siteInfo.value?.titleShortform ?? ''),
  });
}

onMounted(async () => {
  if (import.meta.client) {
    if (lastContentHubScrollTop.value) {
      window.scrollTo({
        top: lastContentHubScrollTop.value,
      });
    }
  }
});
</script>

<style scoped lang="scss">
.layout-default {
  > .header,
  > .module {
    position: relative;
  }

  > .mark {
    margin: var(--base-component-padding);
    display: block;
    font-size: 1rem;
    background-color: #ffad8f;
    width: fit-content;
  }
}

.header-home {
  @media (--vs) {
    padding: 1rem 1.5rem 2rem;
  }

  @media (--vl) {
    padding: 3.625rem 4rem 4rem;
  }

  > .title {
    text-wrap: pretty;

    @media (--vs) {
      line-height: 120%;
    }

    :deep(p::first-letter) {
      margin-left: 4ch;
    }
  }
}
</style>
